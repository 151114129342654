/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const StyledTypography = styled(Typography)<any>(({ theme } : any) => ({
    ...theme,
    'fontFamily': 'Allura',
    paddingTop: '10px',
    paddingBottom: '10px',
    textShadow: '1px 1px 2px pink'
}));

export default function Component(props: any) {
    return <StyledTypography {...props}/>;
}
