import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import PinInput from 'react-pin-input';
import { Container, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Snowfall from 'react-snowfall';
import StyledTypography from './components/StyledTypography';
import { Slideshow } from './components/Slideshow';

function App() {

    const CorrectValues: number[] = [

        2609,
        3108,
        507,
    ];

    const [isValid, setIsValid] = useState<boolean>(false);
    const [validCodes, setValidCodes] = useState<boolean[]>([]);

    function validateInput(index: number, input: number|string) {

        const newValidCodes = validCodes.slice();
        if(input == CorrectValues[index]) {

            newValidCodes[index] = true;

        }else{

            newValidCodes[index] = false;
        }
        setValidCodes(newValidCodes);

        if(newValidCodes.length === CorrectValues.length
            && newValidCodes.every((_) => !!_)) {

            setIsValid(true);
        }else{

            setIsValid(false);
        }
    }

    console.log(validCodes);

    return (
        <Container className="App">
            <StyledTypography variant='h3'>Anun</StyledTypography>
            <StyledTypography variant='h3'>joulukalenteri</StyledTypography>
            <StyledTypography variant='h4'>2023</StyledTypography>
            <Snowfall
                snowflakeCount={10}
                speed={[0.05, 0.12]}
                radius={[2.5, 5]}
                wind={[-0.3, 1]}
                style={{'zIndex': 999998}}
            />
            <StyledTypography variant='h4'>1. Etsintä</StyledTypography>
            <PinInput
                length={4}
                initialValue=""
                secret
                secretDelay={1000}
                onComplete={(value, index) => {validateInput(0, value)}}
                type="numeric"
                inputMode="number"
                style={{padding: '10px'}}
                inputStyle={{borderColor: 'red', fontSize:30, background: '#99999999'}}
                inputFocusStyle={{borderColor: 'blue'}}

                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
            {validCodes[0] == true ? <DoneIcon style={{color: 'green'}}/> : validCodes[0] == false ? <CloseIcon/> : '' }
            <StyledTypography variant='h4'>2. Laskenta</StyledTypography>
            <PinInput
                length={4}
                initialValue=""
                secret
                secretDelay={1000}
                onComplete={(value, index) => {validateInput(1, value)}}
                type="numeric"
                inputMode="number"
                style={{padding: '10px'}}
                inputStyle={{borderColor: 'red', fontSize:30, background: '#99999999'}}
                inputFocusStyle={{borderColor: 'blue'}}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
            {validCodes[1] == true ? <DoneIcon style={{color: 'green'}}/> : validCodes[1] == false ? <CloseIcon/> : '' }
            <StyledTypography variant='h4'>3. Pähkinä</StyledTypography>
            <PinInput
                length={4}
                initialValue=""
                secret
                secretDelay={1000}
                onComplete={(value, index) => {validateInput(2, value)}}
                type="numeric"
                inputMode="number"
                style={{padding: '10px'}}
                inputStyle={{borderColor: 'red', fontSize:30, background: '#99999999'}}
                inputFocusStyle={{borderColor: 'blue'}}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
            {validCodes[2] == true ? <DoneIcon style={{color: 'green'}}/> : validCodes[2] == false ? <CloseIcon/> : '' }
            <StyledTypography  style={{color: isValid ? 'green' : 'red'}} variant='h5'>Koodi ratkaistu: {isValid ? 'kyllä' : 'ei'}</StyledTypography>

            {isValid ?
                <Slideshow/>
                : null
            }


        </Container>
    );
}

export default App;
