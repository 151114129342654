import { Container, Grid, makeStyles, Stack, Typography } from "@mui/material";

import { Box } from "@mui/system";
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import React from 'react';
import Fade from '@mui/material/Fade';

const Item = styled(Box)(({ theme }) => ({
    backgroundColor: "#979797",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#02004f',
    fontFamily: "math"
}));

const fadeEasing = (factor = 1) => {
    return {
        enter: 2000 * factor, exit: 3000 * factor
    };
}

const transitionDelay = (delaySecs = 1) => {

    return {
        transitionDelay: `${delaySecs * 1000}ms`
    };
};

export { Item, fadeEasing, transitionDelay };

export function Slideshow() {


    return (
        <>
            <Fade timeout={fadeEasing(6)} in={true}>
                <img style={{maxWidth: '100%'}} src='img/map.png'></img>
            </Fade>
        </>
    );
}